/*--------------------------------------------------------------
# Body
-------------------------------------------------------------*/
/* @font-face {
	font-family: sf_sansbold;
	src: url(./../public/font/sf-pro-text_bold.woff2) format('woff2'),
		url(./../public/font/sf-pro-text_bold.woff2) format('woff');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: sf_sansmedium;
	src: url(./../public/font/sf-pro-display_semibold.woff2) format('woff2'),
		url(./../public/font/sf-pro-display_semibold.woff2) format('woff');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: sf_sansregular;
	src: url(./../public/font/sf-pro-text_regular.woff2) format('woff2'),
		url(./../public/font/sf-pro-text_regular.woff2) format('woff');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: oregano_regular;
	src: url(./../public/font/Oregano-Regular.ttf) format('woff2'),
		url(./../public/font/Oregano-Regular.ttf) format('woff');
	font-weight: 400;
	font-style: normal;
} */

@font-face {
	font-family: sf_sansbold;
	src: local('Custom Font'), url(./../public/font/Inter-Bold.ttf) format('truetype');
	font-weight: 400;
	font-style: normal;
  font-display: swap;
}

@font-face {
	font-family: sf_sansmedium;
	src: local('Custom Font'), url(./../public/font/Inter-SemiBold.ttf) format('truetype');
	font-weight: 600;
	font-style: normal;
  font-display: swap;
}

@font-face {
	font-family: sf_sansregular;
	src: local('Custom Font'),  url(./../public/font/Inter-Light.ttf) format('truetype');
	font-weight: 400;
	font-style: normal;
  font-display: swap;
}

@font-face {
	font-family: oregano_regular;
	src: local('Custom Font'), url(./../public/font/Oregano-Regular.ttf) format('truetype');
	font-weight: 400;
	font-style: normal;
  font-display: swap;
}

html, body {
	height: 100%;
	font-family: sf_sansregular;
  letter-spacing: 0.02em;
  scroll-behavior: smooth;
  color: #000;
}

.bold {
	font-family: sf_sansbold;
}

.medium {
	font-family: sf_sansmedium;
}

.regular {
	font-family: sf_sansregular;
}

a {
  color: var(--bs-link-color);
  text-decoration: none;
} 

a:hover {
  text-decoration: none;
}

b, .fw-bold, h1, h2, h3, h4, h5 {
  font-family: sf_sansmedium;
  /* text-align: left; */
}

ol, ul {
  padding-left: 2rem;
}

.btn-main {
  border: 1px solid #ced4da;
  font-size: 16px;
  border-radius: 0 !important;
}

.bg-footer {
  background-color: #00000005;
}

.bg-header {
  background-color: #00000005;
}

.bg-main {
  background-color: #00000005;
}
/*--------------------------------------------------------------
# Body END
-------------------------------------------------------------*/
/*--------------------------------------------------------------
# App
-------------------------------------------------------------*/
.App {
  text-align: center;
  font-family: sf_sansregular;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/*--------------------------------------------------------------
# App END
-------------------------------------------------------------*/
/*--------------------------------------------------------------
# Nav Bar
-------------------------------------------------------------*/

.nav-item {
  display: flex;
  font-size: 16px;
}

.navbar-toggler {
  border-radius: 0px;
  border: 0px;
}

.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 0;
}

.nav-link {
  color: #000;
  text-decoration: none;
} 

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: normal;
}

.bd-navbar .navbar-nav .nav-link:hover, .bd-navbar .navbar-nav .nav-link:focus {
  /* color: #6B6B6B; */
  color: var(--bs-link-hover-color);
}

.dropdown-header {
  font-family: sf_sansmedium;
  font-size: 11px;
  color: #fff;
  padding: .5rem !important;;
  padding-top: .2rem !important;
  padding-bottom: .2rem !important;
  text-transform: uppercase;

  border-left: 5px solid #212529;
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb),var(--bs-bg-opacity)) !important;

}

@media (min-width: 768px) {
  .d-md-none {
    display: block !important;
  }
}

@media (max-width: 992px) {
  .nav-custom-menu {
    display: none !important;
  }
}

@media (min-width: 992px) {
  .d-md-none {
    display: none !important;
  }

  .nav-custom-menu {
    display: block !important;
  }

  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: 100%;
  }
}

/*--------------------------------------------------------------
# Nav Bar END
-------------------------------------------------------------*/
/*--------------------------------------------------------------
# Footer END
-------------------------------------------------------------*/
.bd-footer a:hover, .bd-footer a:focus {
  color: var(--bs-link-hover-color);
  text-decoration: underline;
}

.bd-footer a:active {
  color: var(--bs-link-hover-color);
  text-decoration: none;
}

.bd-footer a {
  color: #161616;
  text-decoration: none;
}

.list-unstyled {
  font-size: 14px;
}

.footer-tag {
  /* letter-spacing: 2px; */
  font-size: 1rem;
  font-weight: 700;
}
/*--------------------------------------------------------------
# Footer END
-------------------------------------------------------------*/
/*--------------------------------------------------------------
# HOME
-------------------------------------------------------------*/
.list-group-item {
  position: relative;
  display: block;
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  text-decoration: none;
  background-color: rgba(255, 255, 255, 0);;
  border: 0px;
  font-size: 16px;
}

.wave-bg {
  position: absolute;
  top: 48px;
  width: 100%;
  height: 200px;
  background-color: #fff;
  z-index: -1;
}

.hero-bg {
  background-image: url("./../public/img/svg/Hexagon.svg");
}
/* 
  BG Color : #f8f9fa 
*/
/*--------------------------------------------------------------
# HOME END
-------------------------------------------------------------*/
/*--------------------------------------------------------------
# PreLoading 
-------------------------------------------------------------*/
.loader {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: #ffffff70;
}

.book {
  top: 50%;
  transform: translateY(-50%);
  position: relative;
  margin: 0 auto;
  border: 5px solid #eee;
  width: 100px;
  height: 60px;
}

.book__page {
  position: absolute;
  left: 50%;
  top: -5px;
  margin: 0 auto;
  border-top: 5px solid #eee;
  border-bottom: 5px solid #eee;
  border-right: 5px solid #eee;
  /* background: #97a6b5; */
  background: #798c9f;;
  width: 50px;
  height: 60px;
  transform-origin: 0% 50%;
  animation: flip 1.2s infinite linear;
  animation-fill-mode: forwards;
}

.book__page:nth-child(1) {
z-index: -1;
animation-delay: 1.4s;
}
.book__page:nth-child(2) {
z-index: -2;
animation-delay: 2.8s;
}
.book__page:nth-child(3) {
z-index: -3;
animation-delay: 4.2s;
}
@keyframes flip {
0% {
  transform: perspective(600px) rotateY(0deg);
}
20% {
  background: #d2d9df;
}
29.9% {
  background: #b5bfca;
}
30% {
  transform: perspective(200px) rotateY(-90deg);
  background: #97a6b5;
}
54.999% {
  opacity: 1;
}
55% {
  opacity: 0;
}
60% {
  transform: perspective(200px) rotateY(-180deg);
  background: #97a6b5;
}
100% {
  transform: perspective(200px) rotateY(-180deg);
  background: #798c9f;
}
}
/*--------------------------------------------------------------
# PreLoading END
-------------------------------------------------------------*/
/*--------------------------------------------------------------
# Cookiealert 
-------------------------------------------------------------*/
.cookiealert {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  margin: 0 !important;
  z-index: 999;
  opacity: 0;
  visibility: hidden;
  border-radius: 0;
  transform: translateY(100%);
  transition: all 500ms ease-out;
  color: #ecf0f1;
  background: #404040;
  font-size: 11px;
}

.cookiealert.show {
  opacity: 1;
  visibility: visible;
  transform: translateY(0%);
  transition-delay: 1000ms;
}

.cookiealert a {
  text-decoration: underline
}

.cookiealert .acceptcookies {
  margin-left: 10px;
  vertical-align: baseline;
}
/*--------------------------------------------------------------
# Cookiealert End
-------------------------------------------------------------*/
/*--------------------------------------------------------------
# Common Element
-------------------------------------------------------------*/
.link {
  color: #06c;
  letter-spacing: inherit;
}

.linkwhite {
  color: #fff;
  letter-spacing: inherit;
}

.doc-hashtag {
	scroll-margin-top: 40px;
}

.doc-hashtag-2 {
	scroll-margin-top: 100px;
}

.text-justify {
  text-align: justify;
}

.shadow-app {
  box-shadow: 0 0.5rem 1rem rgba(0,0,0,0.05),inset 0 -1px 0 rgba(0,0,0,0.15);
} 

.dropdown-item.active, .dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color: var(--bs-link-hover-color);
}

.btn-check:active .btn:focus, .btn-check:checked + .btn:focus, .btn.active:focus, .btn.show:focus, .btn:active:focus {
  box-shadow: none;
}

.btn-close:focus {
  box-shadow: 0 0 0 0;
}

.btn-check:focus .btn, .btn:focus {
  box-shadow: none;
}

.brand-logo-font {
  font-family: oregano_regular;
}

.brand-logo {
  font-family: oregano_regular;
  font-size: 2rem;
  text-decoration: none;
}

.brand-logo-2 {
  font-family: oregano_regular;
  font-size: 3rem;
  text-decoration: none;
}

.mobile-text {
  text-align: left !important;
}

@media (max-device-width: 600px) {
  .mobile-text {
    text-align: center !important;
  }
}

.font-14px {
  font-size:14px;
}

.font-15px {
  font-size:15px;
}

.font-16px {
  font-size:16px;
}

.fz-h1 {
  font-size: 2.5rem;
}

.fz-h2 {
  font-size: 2rem;
}

.fz-h3 {
  font-size: 1.75rem;
}

.fz-h4 {
  font-size: 1.5rem;
}

.fz-h5 {
  font-size: 1.25rem;
}
/*--------------------------------------------------------------
# Common Element END
-------------------------------------------------------------*/
/*--------------------------------------------------------------
# Blog
-------------------------------------------------------------*/
/* h1, h2, h3, h4, h5, h6 {
  font-family: "Playfair Display", Georgia, "Times New Roman", serif;
} 

.display-4 {
  font-size: 2.5rem;
}

@media (min-width: 768px) {
  .display-4 {
    font-size: 3rem;
  }
} */

.flex-auto {
  flex: 0 0 auto;
}

.h-250 { height: 250px; }
@media (min-width: 768px) {
  .h-md-250 { height: 250px; }
}

/* Pagination */
.blog-pagination {
  margin-bottom: 4rem;
}

.blog-post {
  margin-bottom: 4rem;
  font-size: 19px;
  text-align: justify;
}
@media (max-width: 768px) {
  .blog-post {
    margin-bottom: 4rem;
    font-size: 17.1px;
    text-align: justify;
  }
}

.blog-author {
  border-left: 5px solid #212529;
  font-size: 14px;
}

.blog-tag {
  border-left: 5px solid #212529;
  font-size: 12px;
}

.blog-tag-align {
  text-align: end;
}

@media (max-width: 576px) {
  .blog-tag-align {
    text-align: left;
  }
}

.blog-post-meta {
  margin-bottom: 1.25rem;
  color: #727272;
}

.blog-asterisk-background {
  font-size: 14px;
  background-color: #FAFAFA;
}

.blog-asterisk {
  font-size: 21px;
  text-align: end;
  margin-top: -1.5rem !important;
}
/*--------------------------------------------------------------
# Blog End
-------------------------------------------------------------*/
/*--------------------------------------------------------------
# Artical SideBar
-------------------------------------------------------------*/
.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link-sidebar {
  display: block;
  padding: .5rem 1rem;
  color: #3b71ca;
  text-decoration: none;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;
}

.nav-pills {
  margin-left: -.5rem;
}

.flex-column {
  flex-direction: column !important;
}

.nav-pills.menu-sidebar .nav-link-sidebar:hover {
  color: var(--bs-link-hover-color);
}

.nav-pills.menu-sidebar .nav-link-sidebar {
  font-size: 14px;
  background-color: transparent;
  color: #000;
  line-height: 1.1rem;
  padding: 0 5px;
  font-weight: 400;
  transition: all .2s ease-in-out;
  text-transform: none;
  margin-top: 3px;
  margin-bottom: 3px;
}
@media (min-width: 768px) {
  .nav-pills.menu-sidebar .nav-link-sidebar {
    font-size: 13px;
  }
}
@media (min-width: 992px) {
  .nav-pills.menu-sidebar .nav-link-sidebar {
    font-size: 16px;
  }
}

.nav-pills.menu-sidebar .nav-link-sidebar.active, .nav-pills.menu-sidebar .show > .nav-link-sidebar {
  background-color: transparent;
  box-shadow: none;
  color: #0d6efd;
  font-weight: 600;
  border-left: .125rem solid #0d6efd;
  border-radius: 0;
}

@media (max-width: 768px) {
  .notificationcard {
    display: none;
  }
}
/*--------------------------------------------------------------
# Artical SideBar END
-------------------------------------------------------------*/
/*--------------------------------------------------------------
# Artical Meta 
-------------------------------------------------------------*/
.article-meta {
  margin-top: 20px;
  margin-bottom: 20px;
}

.article-meta-tital {
  font-size: 16px;
}

.article-meta-name {
  font-size: 30px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

@media (max-width: 979px) {
  .article-meta-name {
    font-size: 24px;
  }
}

@media (min-width: 0px) and (max-width: 599px) {
  .article-meta-name {
    font-size: 16px;
  }
}
/*--------------------------------------------------------------
# Artical Meta END
-------------------------------------------------------------*/
/*--------------------------------------------------------------
# Image
-------------------------------------------------------------*/
.cropper-view-box {
  display: block;
  height: 100%;
  outline: 4px solid #fff;
  overflow: hidden;
  width: 100%;
}

.cropper-dashed {
  border: 0 dashed #fff;
  display: block;
  opacity: 0.5;
  position: absolute;
}

.cropper-point {
  background-color: #fff;
  height: 5px;
  opacity: 1;
  width: 5px;
}

.cropper-point.point-sw {
  bottom: -2px;
  cursor: nesw-resize;
  left: -2px;
}

.cropper-point.point-nw {
  cursor: nwse-resize;
  left: -1px;
  top: -1px;
}

.cropper-point.point-ne {
  cursor: nesw-resize;
  right: -1px;
  top: -1px;
}

.cropper-point.point-se {
  height: 5px;
  width: 5px;
  right: -2px;
  bottom: -2px;
  opacity: 1;
}

@media (min-width: 992px) {
  .cropper-point.point-se {
    height: 5px;
    width: 5px;
  }
}

@media (min-width: 768px) {
  .cropper-point.point-se {
    height: 5px;
    width: 5px;
  }
}
/*--------------------------------------------------------------
# Image END
-------------------------------------------------------------*/
/*--------------------------------------------------------------
# Common 
-------------------------------------------------------------*/
.mw-50 {
  max-width: 50px;
}

.mw-104px {
  max-width: 104px;
}

.form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #fff;
  outline: 0;
  box-shadow: 0 0 0 0 #fff;
  border: 1px solid #ced4da;
}

.progress {
  --bs-progress-height: 0.2rem;
}

.img-box {
  background: #fff;
  border: 1px solid #dee2e6 !important;
  padding: 10px;
  margin-top: .5rem;
  margin-bottom: .5rem 
}

.border-bottom-secondary {
  border-bottom: .25rem solid #858796 !important;
}

.border-top-secondary {
  border-top: .25rem solid #858796 !important;
}

.border-left-dark {
  border-left: .25rem solid #5a5c69 !important;
}
/*--------------------------------------------------------------
# Common END
-------------------------------------------------------------*/
/*--------------------------------------------------------------
# Character Count 
-------------------------------------------------------------*/
.characterCountTextArea {
  resize: none;
  vertical-align:top;
  margin: 0;
  padding: 0;
  border: 0;
  width: 100%;
  height: 100%;
  font-size: 20px;
  outline: none;
  min-height: 340px;
}
/*--------------------------------------------------------------
# Character Count END
-------------------------------------------------------------*/